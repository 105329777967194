import './App.css';
import {Link, Route, Routes} from "react-router-dom";
import React from "react";
import Home from "./Home";
import AgeCal from "./AgeCal";
import {CalendarMonth, CurrencyExchange, Token, Speaker} from '@mui/icons-material';
import TokenComp from "./TokenComp";
import Sound from "./Sound";
function App() {
  return (
    <div className="App">
      <header style={{backgroundColor:"darkblue", color: "white", padding:20, fontSize: 30}}>
        <Link title={"Currency Exchange"} style={{margin: 5, textDecoration: "none", color: "white"}} to="/">
            <CurrencyExchange style={{fontSize: 50}}/>
        </Link>
        &nbsp;&nbsp;&nbsp;
        <Link title={"Age Convertor"} style={{margin: 5, textDecoration: "none", color: "white"}} to="age-calculator">
            <CalendarMonth style={{fontSize: 50}}/>
        </Link>
        &nbsp;&nbsp;&nbsp;
        <Link title={"Token"} style={{margin: 5, textDecoration: "none", color: "white"}} to="token">
          <Token style={{fontSize: 50}}/>
        </Link>
        &nbsp;&nbsp;&nbsp;
        <Link title={"Sound"} style={{margin: 5, textDecoration: "none", color: "white"}} to="sound">
          <Speaker style={{fontSize: 50}}/>
        </Link>
      </header>

      <Routes>
        <Route path="/" element={ <Home/> } />
        <Route path="/age-calculator" element={ <AgeCal/> } />
        <Route path="/token" element={ <TokenComp/> } />
        <Route path="/sound" element={ <Sound/> } />
      </Routes>
    </div>
  );
}

export default App;
