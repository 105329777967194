import React, {useEffect, useState} from 'react';
import moment from "moment";
function Sound() {
    const [time, setTime] = useState(moment().format("H:m:s"));
    const tickClockSound = new Audio('/clockTick.mp3');
    const [playSound, setPlaySound] = useState(false);
    useEffect(() => {
        setInterval(() => {
            const t = moment().format("H:m:s");

            setTime(t);
        },1000)
    })
    useEffect(() => {
        if(playSound) {
            tickClockSound.play();
        }
    },[time,playSound])
    return (
        <div className="container mt-5">
            <h1 className="text-decoration-underline text-center mb-5">Sound</h1>
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <h1 style={{fontSize: 50}}>Time:  {time}</h1>
                    <button onClick={() => {
                        setPlaySound(true);
                    }} className={'btn btn-success'}>play clock sound</button>
                    &nbsp;
                    &nbsp;
                    &nbsp;
                    <button onClick={() => {
                        setPlaySound(false);
                    }} className={'btn btn-danger'}>stop clock sound</button>
                </div>
            </div>
        </div>
    );
}

export default Sound;
