import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import moment from "moment";

function AgeCalculator() {
    const [birthdate, setBirthdate] = useState('');
    const [age, setAge] = useState({
        timeLeft: '',
        day: ''
    });

    const calculateAge = () => {
        const birthdateObj = new Date(birthdate);
        // const currentDateObj = new Date();
        const today = moment();
        const bbMoment = moment(birthdateObj);
        const nextBirthday = bbMoment.year(today.year());
        if (nextBirthday.isBefore(today)) {
            nextBirthday.add(1, "year");
        }

        const timeLeft = moment.duration(nextBirthday.diff(today));
        // TODO : number of days left to the next birthday and then we need to get day.
        const timeLeftHum = timeLeft.humanize();
        const day = nextBirthday.format("dddd, MMMM Do YYYY");

        setAge({
            timeLeft: timeLeftHum,
            day,
        });
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        calculateAge();
    };

    return (
        <div className="container mt-5">
            <h1 className="text-center mb-3">Age Calculator</h1>
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <Form onSubmit={handleFormSubmit}>
                        <Form.Group controlId="birthdate">
                            <Form.Label>Enter your birthdate:</Form.Label>
                            <Form.Control
                                type="date"
                                value={birthdate}
                                onChange={(e) => setBirthdate(e.target.value)}
                            />
                        </Form.Group>

                        <Button className={"m-3"} variant="primary" type="submit">
                            Calculate Age
                        </Button>
                    </Form>
                </div>
            </div>
            {age.years !== '' && (
                <div className="row justify-content-center mt-5">
                    <div className="col-md-6">
                        <p className="lead text-center">
                            {age.timeLeft} - {age.day}
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AgeCalculator;
