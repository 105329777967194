import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from "moment";
function CurrencyConverter() {
  const [czkAmount, setCzkAmount] = useState('');
  const [foreignCurrencyCode, setForeignCurrencyCode] = useState('');
  const [foreignAmount, setForeignAmount] = useState('');
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('en');
  const [selectedDate, setSelectedDate] = useState(moment(Date.now()).format("YYYY-MM-DD"))
  const [rates, setRates] = useState([]);
  const [formLabels, setFormLabels] = useState([
      "Country",
      "Currency",
      "Amount",
      "Code",
      "Rate",
      "Language"
  ]);
  useEffect(() => {
    const url = `https://homel.vsb.cz/~mor03/TAMZ/cnb_json.php?lang=${selectedLanguage}&date=${moment(selectedDate).format("YYYY-MM-DD")}`;
    fetch(url)
        .then(response => response.json())
        .then(({ data, labels }) => {
          setLanguages(data);
          labels.push(selectedLanguage === "cs" ? "Jazyk" : "Language")
          labels.push(selectedLanguage === "cs" ? "Datum" : "Date")
          setFormLabels(labels);
          setForeignCurrencyCode(data[0].code);
          setRates(data.reduce((acc, curr) => {
            acc[curr.code] = curr.rate;
            return acc;
          }, {}));
        })
        .catch(error => {
          console.error(error);
        });
  }, [selectedLanguage, selectedDate]);

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value);
  };

  const handleCzkAmountChange = (event) => {
    setCzkAmount(event.target.value);
  };

  const handleForeignCurrencyCodeChange = (event) => {
    setForeignCurrencyCode(event.target.value);
  };

  useEffect(() => {
    const foreignAmountValue = czkAmount / rates[foreignCurrencyCode];
    setForeignAmount(foreignAmountValue.toFixed(2) + ' ' + foreignCurrencyCode);
  }, [czkAmount, foreignCurrencyCode, rates]);

  return (
      <div className="container mt-5">
        <h1 className="text-center mb-3">Currency Converter</h1>
        <div className="row justify-content-center">
          <div className="col-md-6">
            <form>
              <div className="form-group">
                <label htmlFor="czk-input">{formLabels[2]} in CZK</label>
                <input
                    type="number"
                    className="form-control"
                    id="czk-input"
                    placeholder="Enter amount in CZK"
                    value={czkAmount}
                    onChange={handleCzkAmountChange}
                />
              </div>

                <div className="form-group">
                    <label htmlFor="czk-input">{formLabels[formLabels.length - 1]}</label>
                    <input
                        type="date"
                        className="form-control"
                        placeholder={formLabels[formLabels.length - 1]}
                        value={selectedDate}
                        onChange={(e) => setSelectedDate(e.target.value)}
                    />
                </div>
              <div className="form-group">
                <label htmlFor="foreign-currency-select">{formLabels[0]}</label>
                <select
                    className="form-control"
                    id="foreign-currency-select"
                    value={foreignCurrencyCode}
                    onChange={handleForeignCurrencyCodeChange}
                >
                  {languages.map((language) => (
                      <option key={language.code} value={language.code}>
                        {language.code} - {language.country_label}
                      </option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="language-select">{formLabels[5]}</label>
                <select
                    className="form-control"
                    id="language-select"
                    value={selectedLanguage}
                    onChange={handleLanguageChange}
                >
                  <option value="cs">cs</option>
                  <option value="en">en</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="foreign-amount-label">{formLabels[1]} {formLabels[2]}</label>
                <p id="foreign-amount-label" className="form-control-static">
                  {foreignAmount}
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
  );
}

export default CurrencyConverter;
