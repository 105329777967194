import React, {useEffect, useState} from 'react';

function TokenComp() {
    const [userLogin, setUserLogin] = useState("");
    const [result, setResult] = useState('');
    const [seperatedToken, setSeperatedToken] = useState('');
    useEffect(() => {
        if(userLogin !== "") {
            const timeStamp = Date.now() ;
            const url = `https://homel.vsb.cz/~mor03/TAMZ/TAMZ22.php?user=${userLogin}&timestamp=${timeStamp}`;
            fetch(url).then((e) => {
                e.text().then(data => {
                    const r = atob(data);
                    setResult(r);
                    setSeperatedToken(r.slice(userLogin.length + 1));
                });
            });
        }
    },[userLogin])

    return (
        <div className="container mt-5">
            <h1 className="text-center mb-3">Token</h1>
            <div style={{margin: 23}} className="row justify-content-center">
                <div className="col-md-6">
                    <input className={'m-4'} onChange={(e) => {
                        setUserLogin(e.target.value)
                    }}
                />
                    <h6><span style={{color:'red', fontWeight: 'bolder' }}>Token:</span> {result}</h6>
                    <h6><span style={{color:'red', fontWeight: 'bolder' }}>User:</span> {userLogin}</h6>
                    <h6><span style={{color:'red', fontWeight: 'bolder' }}>Seperated Token:</span> {seperatedToken}</h6>
                </div>
            </div>
        </div>
    );
}

export default TokenComp;
